
import Vue from 'vue';
import { HeadlineSettings } from '@root/modules/headlines/types/headlines';
import { HeadlineImage, HeadlinePublishDate, HeadlineTitle } from '@headlines/components/fragments';
import { Article, EmbeddedArticlesElement, EmbeddedArticle } from '@root/modules/article/types/getArticleByID';
import { articleUrl } from '@root/common/filters/urlBuilders';
import { getRootConfig } from '@core/utils/helpers';

const PodcastEmbed = () => import(/* webpackChunkName: "PodcastEmbed" */ '@root/modules/podcast/components/PodcastEmbed.vue');

interface Props {
  article: Article;
  content: Record<string, any>;
}

interface Computed {
  classModifier: string[];
  isPodcastEmbed: boolean;
  header: string;
  embeddedArticles: EmbeddedArticlesElement | null;
  type: string;
}

interface Data {
  headlines: EmbeddedArticle[];
  headlineSettings: HeadlineSettings;
}

interface Methods {
  getHeadlines: (data: EmbeddedArticle[]) => EmbeddedArticle[];
  getHeadlineClassModifier: (type: string) => string[];
  getPictureSrcSet: (type: string) => Record<string, any>;
}

export default Vue.extend<Data, Methods, Computed, Props>({
  components: {
    HeadlineImage,
    HeadlinePublishDate,
    HeadlineTitle,
    PodcastEmbed,
  },
  props: {
    article: {
      type: Object,
      required: true,
    },
    content: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      headlines: [],
      headlineSettings: {
        publishDate: true,
        titleComment: false,
      },
    };
  },
  computed: {
    classModifier() {
      if (this.isPodcastEmbed) {
        return ['fragment--podcast-embed'];
      }

      const classModifier = ['fragment-related-articles'];

      classModifier.push(`fragment-related-articles--${this.type}`);

      return classModifier;
    },
    isPodcastEmbed() {
      return Boolean(this.embeddedArticles?.items?.length === 1 && this.embeddedArticles?.items[0].primaryCategory.channel.url === 'www.delfi.lt/fone');
    },
    embeddedArticles() {
      if (!this.$props.content.attrs.relatedArticles || !this.$props.article.embeddedArticles) {
        return;
      }

      return this.$props.article.embeddedArticles.find((embeddedArticle: EmbeddedArticlesElement) => embeddedArticle.id === this.$props.content.attrs.id);
    },
    header() {
      return this.$props.content.attrs.text.html;
    },
    type() {
      return this.$props.content.attrs.type;
    },
  },
  methods: {
    getHeadlines(items) {
      const settings = getRootConfig('settings');
      const domain = settings?.domain;
      const fakeDomainEnabled = this.$config.FAKE_DOMAIN_ENABLED;

      const headlines = items.map((headline) => {
        return { ...headline, href: articleUrl({ article: headline as Article, domain, fakeDomainEnabled }) };
      });

      return headlines;
    },
    getHeadlineClassModifier(type) {
      const classModifier: string[] = [];

      classModifier.push(`fragment-related-articles__headline--${type}`);

      switch (type) {
        case 'double':
          classModifier.push('col-6');
          break;
      }

      return classModifier;
    },
    getPictureSrcSet(type) {
      if (type === 'double') {
        return { xs: { w: 384, h: 384, r: '1:1' }, sm: { w: 882, h: 882, r: '1:1' }, lg: { w: 1264, h: 1264, r: '1:1' } };
      }

      return { xs: { w: 384, h: 216 }, sm: { w: 882, h: 496 }, lg: { w: 1264, h: 711 } };
    },
  },
  async mounted() {
    if (this.embeddedArticles?.items?.length) {
      this.headlines = this.getHeadlines(this.embeddedArticles.items);
    }
  },
});
