
import Vue from 'vue';
import fragmentImporter from '@core/utils/fragmentImporter';
import { FragmentContent, FragmentSettings } from '@core/types/fragment';
import { Article } from '@root/modules/article/types/getArticleByID';

import fragmentConfig from '@core/config/fragment.config';

interface Data {
  contentData: Record<string, any>;
}

interface Methods {
  getEmbedFragmentName: (attributes: FragmentContent['attrs']) => string | null;
  getFragment: (content: FragmentContent) => string;
}

interface Props {
  article: Article;
  content: FragmentContent | string;
  settings: FragmentSettings;
}

export default Vue.extend<Data, Methods, unknown, Props>({
  components: {
    ...fragmentImporter,
  },
  props: {
    article: {
      type: Object,
      required: false,
      default: null,
    },
    content: {
      type: [Object, String],
      required: true,
    },
    settings: {
      type: Object,
      required: false,
      default: null,
    },
  },
  methods: {
    getEmbedFragmentName(attrs) {
      if (!attrs || !attrs?.type) {
        return null;
      }

      const embed = fragmentConfig.type.embed;
      const type = attrs.type && attrs.type.toLowerCase();

      let fragmentName = null;

      if (type === 'custom' && attrs.file && attrs.file !== null) {
        fragmentName = 'FragmentRecurringTable';
      } else if (type === 'custom' && attrs.title && attrs.title === 'Short') {
        fragmentName = 'FragmentDelfiShort';
      } else if ((type === 'custom' && attrs.title && attrs.title === 'OG') || type === 'opengraph') {
        fragmentName = 'FragmentOgEmbed';
      } else if (type === 'custom' && attrs.title && attrs.title === 'Podcast') {
        fragmentName = 'FragmentPodcast';
      } else if (type === 'dofollow') {
        fragmentName = 'FragmentDoFollow';
      } else if (type === 'recipev2') {
        fragmentName = 'FragmentRecipe';
      } else if (type) {
        fragmentName = embed[type] || embed['default'];
      }

      return fragmentName;
    },
    // Get correct fragment for render
    // If fragmentType is missing then import FragmentHtml as fallback
    getFragment(content) {
      const { attrs } = content;
      const type = content.type && content.type.toLowerCase();

      let fragmentName: string | null = fragmentConfig.type[type] || null;

      if (type === 'media') {
        const mediaType = attrs?.type?.toLowerCase() || '';
        fragmentName = fragmentConfig.type[type][mediaType];
      }

      if (type === 'embed') {
        fragmentName = this.getEmbedFragmentName(attrs);
      }

      if (type === 'live_embed') {
        fragmentName = 'FragmentVideo';
      }

      if (type === 'text' && /embedded_article_video:/.test(content.html)) {
        fragmentName = 'FragmentVideo';
      }

      if (type === 'heading' && /Teiginiai|Verdiktas|Melo detektoriaus komentaras/.test(content.html)) {
        fragmentName = 'FragmentMDHeading';
      }

      if (type === 'recipev2') {
        fragmentName = 'FragmentRecipe';
      }

      if (type === 'relatedarticleselement') {
        fragmentName = 'FragmentRelatedArticles';
      }

      if (fragmentName && this?.$options?.components && !(fragmentName in this.$options.components)) {
        return fragmentConfig.type['html'];
      }

      if (this.$options.components![`${fragmentName}`]) {
        return fragmentName;
      }
      return fragmentConfig.type['html'];
    },
  },
});
