import dayjs, { OpUnitType } from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import 'dayjs/locale/lt';
import utc from 'dayjs/plugin/utc';

dayjs.locale('lt');
dayjs.extend(utc);
dayjs.extend(isBetween);
dayjs.extend(isSameOrBefore);

interface IsDateSame {
  date?: string;
  beforeDate: string;
  measureUnit: OpUnitType;
}

const formatCustomDate = (val: string | number, formatType: string) => String(val && formatType && dayjs(val).format(formatType));
const isDateBetween = (current: string, startTime: string, endTime: string) =>
  Boolean(current && startTime && endTime && dayjs(current).isBetween(startTime, endTime));
const isDateBefore = (date: string, beforeDate: string) => Boolean(date && beforeDate && dayjs(date).isBefore(beforeDate));
const isDateSameOrBefore = (date: string, beforeDate: string) => Boolean(date && beforeDate && dayjs(date).isSameOrBefore(beforeDate));
const isDateSame = ({ date, beforeDate, measureUnit }: IsDateSame) => dayjs(date).isSame(beforeDate, measureUnit);

export { formatCustomDate, isDateBetween, isDateSameOrBefore, isDateSame, isDateBefore };
