import FragmentHtml from '@core/components/fragments/FragmentHtml.vue';
import FragmentPicture from '@core/components/fragments/FragmentPicture.vue';
import FragmentIframe from '@core/components/fragments/FragmentIframe.vue';
import FragmentSidebar from '@core/components/fragments/FragmentSidebar.vue';
import FragmentPullout from '@core/components/fragments/FragmentPullout.vue';
import FragmentQuiz from '@core/components/fragments/FragmentQuiz.vue';
import FragmentJsmk from '@core/components/fragments/FragmentJsmk.vue';
import FragmentEmbed from '@core/components/fragments/FragmentEmbed.vue';
import FragmentDstream from '@core/components/fragments/FragmentDstream.vue';
import FragmentRecurringTable from '@core/components/fragments/FragmentRecurringTable.vue';
import FragmentDelfiShort from '@core/components/fragments/FragmentDelfiShort.vue';
import FragmentOgEmbed from '@core/components/fragments/FragmentOgEmbed.vue';
import FragmentPodcast from '@core/components/fragments/FragmentPodcast.vue';
import FragmentDoFollow from '@core/components/fragments/FragmentDoFollow.vue';
import FragmentMDHeading from '@core/components/fragments/FragmentMDHeading.vue';
import FragmentRecipe from '@core/components/fragments/FragmentRecipe.vue';
import FragmentRelatedArticles from '@core/components/fragments/FragmentRelatedArticles.vue';

export default {
  FragmentHtml,
  FragmentPicture,
  FragmentIframe,
  FragmentSidebar,
  FragmentPullout,
  FragmentQuiz,
  FragmentJsmk,
  FragmentEmbed,
  FragmentDstream,
  FragmentRecurringTable,
  FragmentDelfiShort,
  FragmentOgEmbed,
  FragmentPodcast,
  FragmentDoFollow,
  FragmentMDHeading,
  FragmentRecipe,
  FragmentRelatedArticles,
};
